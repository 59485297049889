import { Fragment } from 'react';

import LscOfferingByOrgUnitIdAndReportPeriodIdDropdown from '../../../components/dropdowns/lscOfferingByOrgUnitIdAndReportPeriodIdDropdown/LscOfferingByOrgUnitIdAndReportPeriodIdDropdown';

import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import ExclamationIcon from '../../../../common/components/icons/ExclamationIcon';
import LookupIcon from '../../../../common/components/icons/LookupIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ invoice, gridData, lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, onDeleteClicked,
  onUpdateSelectedOfferingNameValueChange, orgUnitId, reportPeriodId, showError, onPersonPotentialMatchModalOpenClicked }) => {
  let selectedOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.arrayData?.find((x) =>
    x.offeringName === invoice?.selectedOfferingName);
  // don't consider the invoice offering, but check all other rows to make sure that 
  // they aren't person offerings dupes of the same offering types
  let selectedOfferingSamePersonDupe = gridData?.find((x) =>
    x.selectedOfferingName === invoice?.selectedOfferingName && x.thirdPartyClubRegistrationPersonOfferingId !== invoice.thirdPartyClubRegistrationPersonOfferingId &&
    x.thirdPartyClubRegistrationPersonId === invoice.thirdPartyClubRegistrationPersonId);

  // don't consider the invoice offering, but check all other rows to make sure that 
  // they aren't person offerings dupes of different athlete offering types
  let selectedAthleteOfferingSamePersonDupe = false;
  for (let option of gridData) {
    if (option.thirdPartyClubRegistrationPersonOfferingId !== invoice.thirdPartyClubRegistrationPersonOfferingId) {
      let optionOffering = lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState.arrayData?.find((x) =>
        x.offeringName === option?.selectedOfferingName);
      if ((optionOffering?.isAthleteOffering === true && selectedOffering?.isAthleteOffering === true) &&
        (option?.thirdPartyClubRegistrationPersonId === invoice?.thirdPartyClubRegistrationPersonId)) {
        selectedAthleteOfferingSamePersonDupe = true;
      }
    }
  }
  return (
    <Fragment>
      <tr>
        <td>
          {(invoice.firstName || invoice.middleName || invoice.lastName) ? `${invoice.firstName || ''}  ${invoice.preferredName !== '' && invoice.preferredName !== invoice.firstName ? '"' + invoice.preferredName + '"' : ''} ${invoice.middleName || ''} ${invoice.lastName || ''}` : ''}
        </td>
        <td>{invoice.memberIdValid === true ? invoice.memberId || '' : invoice.hasPossibleMatch === true ? <a onClick={(e) => onPersonPotentialMatchModalOpenClicked(e, invoice)}>View Potential Matches&nbsp;&nbsp;<LookupIcon /></a> : ''}</td>
        <td>{invoice.competitionGenderTypeName || ''}</td>
        <td>{invoice.birthDate ? formatDate(invoice.birthDate) : ''}</td>
        <td>{invoice.submittedOfferingName || ''}</td>
        <td>
          <LscOfferingByOrgUnitIdAndReportPeriodIdDropdown
            orgUnitId={orgUnitId}
            reportPeriodId={reportPeriodId}
            label=""
            name={"selectedOfferingInstance" + invoice?.thirdPartyClubRegistrationPersonId + "thirdPartyClubRegistrationPersonId" + invoice?.thirdPartyClubRegistrationPersonOfferingId + "thirdPartyClubRegistrationPersonOfferingId"}
            value={invoice?.selectedOfferingInstanceId}
            onChange={(newValue, newValueLabel, e) => {
              onUpdateSelectedOfferingNameValueChange(e, invoice, newValue, newValueLabel);
            }}
            areValuesIntegers={false}
            error={showError === true && (invoice?.selectedOfferingInstanceId < 0 || selectedOfferingSamePersonDupe || selectedAthleteOfferingSamePersonDupe === true || (selectedOffering && (invoice.age < selectedOffering?.ageStart ||
              invoice.age > selectedOffering?.ageEnd))) ? true : false}
            message={invoice?.selectedOfferingInstanceId < 0 && showError === true ? 'Please select an Offering Name' :
              (selectedOfferingSamePersonDupe && showError === true) ? `This Offering Name selection is not allowed for this Person, as it is a duplicate. Please select a different Offering.` :
                (selectedAthleteOfferingSamePersonDupe === true && showError === true) ? `This Offering Name selection is not allowed for this Person, as there is another row with a different Athlete Offering for this Person. Please select a different Offering.` :
                  (selectedOffering && (invoice.age < selectedOffering?.ageStart ||
                    invoice.age > selectedOffering?.ageEnd)) && showError === true ?
                    `This Offering Name selection is not allowed for this Person, as their age (${invoice.age || ''}) is outside of the Offering's allowed age range (${selectedOffering?.ageStart || ''} -  ${selectedOffering?.ageEnd || ''}). Please select a different Offering.`
                    : ''} />
        </td>
        <td>{selectedOffering && selectedOffering?.isClubOffering === false && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='The selected Offering Name is not offered by the club for the report period. Please select a valid Offering Name using the dropdown.' />&nbsp;The selected Offering Name is not offered by the club for the report period&nbsp;</span>}
          {invoice.isClubOffering === false && !invoice?.offeringInstanceId && invoice?.selectedOfferingInstanceId === Constants.DEFAULT_ID && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='No available club membership offerings for the report period match the Submitted Offering Name. Please select a valid Offering Name using the dropdown.' />&nbsp;No available club membership offerings for the report period match the Submitted Offering Name&nbsp;</span>}
          {invoice.hasOffering === true && selectedOffering?.isAthleteOffering === true && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText='This person already has an athlete membership for the report period. Please delete this row or select a different membership type.' />&nbsp;This person already has an athlete membership for the report period&nbsp;</span>}</td>
        <td>
          {onDeleteClicked &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={() => onDeleteClicked(invoice)}>
              <DeleteIcon />
            </button>}
        </td>
      </tr>
    </Fragment>
  );
};

const ClubThirdPartyInvoicesDetailGridLarge = ({ lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState, orgUnitId, reportPeriodId, gridData, isLoading, onDeleteClicked,
  onUpdateSelectedOfferingNameValueChange, showError, onPersonPotentialMatchModalOpenClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'Member ID'} columnField={'memberId'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '130px' }} sortableGridState={sortableGridState} columnLabel={'Comp. Category'} columnField={'competitionGenderTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'Birth Date'} columnField={'birthDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '190px' }} sortableGridState={sortableGridState} columnLabel={'Submitted Offering Name'} columnField={'submittedOfferingName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th style={{ minWidth: '200px' }}>Offering Name</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="8">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((invoice, i) =>
              <GridRow key={i} invoice={invoice} gridData={sortableGridState.sortedGridData} lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState={lscOfferingByOrgUnitIdAndReportPeriodIdDropdownState} onDeleteClicked={onDeleteClicked} onUpdateSelectedOfferingNameValueChange={onUpdateSelectedOfferingNameValueChange}
                orgUnitId={orgUnitId} reportPeriodId={reportPeriodId} showError={showError} onPersonPotentialMatchModalOpenClicked={onPersonPotentialMatchModalOpenClicked} />)
            : <tr><td colSpan="8">No Invoices</td></tr>
        }
      </tbody>
    </table>
  )
};

export default ClubThirdPartyInvoicesDetailGridLarge;