import { Fragment } from 'react';

import UseKpiViewBasicReport from './UseKpiViewBasicReport';

import KpiOverrideScoreForm from './KpiOverrideScoreForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReportListAsButton from '../../../../common/components/embeddedReports/ReportListAsButton';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

// TEMP FIX for user story 4669
const MEASURE_NAME_ONLINE_EDUCATION_FOR_PARENTS = 'Online Safe Sport Education for Parents';
const MEASURE_NAME_ONLINE_EDUCATION_FOR_YOUTH = 'Online Safe Sport Education for Youth Athletes';
const SAFE_SPORT_REPORT_ROUTE_NAME = 'SAFE_SPORT_PROGRAM_CATEGORY_KPI';

const Row = ({ row }) => (
  <div className="row usas-extra-top-margin">
    {Array.isArray(row) && row.length > 0 && row.map((field, i) => (
      <div key={i} className="col-xs-12 col-sm-6">
        <Headings.H5>{field.label}: &nbsp; &nbsp;
          <span className={global.ReadOnlyValue}>{field.value}</span>
        </Headings.H5>
      </div>
    ))}
  </div>
);

const ReadOnlyRows = ({ reportFields }) => (
  <Fragment>
    {Array.isArray(reportFields) && reportFields.length > 0 && reportFields.map((row, i) => (
      <Row key={i} row={row} />
    ))}
  </Fragment>
);

const Question = ({ question }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: question }} />
  );
};

const KpiViewBasicReport = ({ programLevel, categoryMeasure, onRedirect, isReadonly, canOverrideScore = false }) => {
  const {
    isLoading,
    isSaving,
    isEdit,
    orgUnitId,
    kpiReportFieldsState,
    submitFormCallback
  } = UseKpiViewBasicReport(programLevel, categoryMeasure, isReadonly, onRedirect);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{categoryMeasure?.measureName}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {categoryMeasure?.question ?
            <Question question={categoryMeasure.question} /> : <Fragment />}
        </div>
      </div>
      {(categoryMeasure?.measureName === MEASURE_NAME_ONLINE_EDUCATION_FOR_PARENTS || categoryMeasure?.measureName === MEASURE_NAME_ONLINE_EDUCATION_FOR_YOUTH) &&
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className='col-xs-12'>
            <ReportListAsButton
              listTitle='KPI Progress Reports'
              routeName={SAFE_SPORT_REPORT_ROUTE_NAME}
              reportParameters={{ categoryMeasureId: categoryMeasure.categoryMeasureId, orgUnitId: orgUnitId }} />
          </div>
        </div>
      }
      <ReadOnlyRows reportFields={kpiReportFieldsState} />
      {canOverrideScore === true && isEdit === true && isReadonly !== true
        ? (
          <KpiOverrideScoreForm
            programLevel={programLevel}
            categoryMeasure={categoryMeasure}
            onRedirect={onRedirect}
            submitFormCallback={submitFormCallback} />
        ) : (
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={() => onRedirect(false)}>Back</SecondaryButton>
            </div>
          </div>
        )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default KpiViewBasicReport;