import { useEffect, useState } from 'react';
// import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useUsasClubThirdPartyInvoicesData from '../../../state/clubThirdPartyInvoices/UseUsasClubThirdPartyInvoicesData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
// import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Third Party Invoice Member Registrations',
  invoice: {}
};

const useClubThirdPartyInvoices = () => {
  // const navigate = useNavigate();
  // const { navRoutes } = useNavRoutes();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubThirdPartyInvoiceState, getThirdPartyInvoices } = useUsasClubThirdPartyInvoicesData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onEditClicked = (invoice) => {
    setModalState({ ...modalState, displayPopUp: true, invoice: invoice  });
    // navigate(navRoutes.CLUB_THIRD_PARTY_INVOICES_DETAIL?.route, { state: { invoice: invoice } });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true && modalState.displayPopUp === false) {
      getThirdPartyInvoices(baseUsasClubState.objData.orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, modalState.displayPopUp]);

  return {
    modalState, 
    onModalCanceled,
    isLoading: baseUsasClubState.isObjLoading || clubThirdPartyInvoiceState.isArrayLoading,
    clubThirdPartyInvoiceState,
    onEditClicked
  };
};

export default useClubThirdPartyInvoices;