import { isValidAddressLine, isValid5DigitZip, isValid9DigitZip } from '../../../utils/validation';
import Constants from '../../../utils/Constants';

const REQUIRED_FILTER_COUNT = 1;

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;
  let isCurrentSelected = false;

  if (formState.organizationId === Constants.DEFAULT_ID) {
    errors.errorOnSubmitAction = 'An error occurred. Please try again.';
  }

  if (formState.lscOrgUnitId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  if (formState.orgUnitName !== '') {
    filterCount += 1;

    if (formState.orgUnitId === '') {
      errors.orgUnitId = 'Must be a valid Club Name';
    }
  }

  if (formState.city.trim() !== '') {
    filterCount += 1;

    if (!isValidAddressLine(formState.city.trim())) {
      errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  if (formState.stateName !== '') {
    filterCount += 1;

    if (formState.stateCode === Constants.DEFAULT_ID || formState.stateCode === '') {
      errors.stateCode = 'Must be a valid State';
    }
  }

  if (formState.zip.trim() !== '') {
    filterCount += 1;

    if (!isValid5DigitZip(formState.zip.trim()) && !isValid9DigitZip(formState.zip.trim())) {
      errors.zip = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
    }
  }

  if (formState.isCurrentId > 1) {
    isCurrentSelected = true;
  }

  if (filterCount < REQUIRED_FILTER_COUNT) {
    if (isCurrentSelected === true) {
      errors.filter = `In addition to 'Club Status', please enter information in at least one more search filter`;
    } else {
      errors.filter = 'Please enter information in at least one search filter';
    }
  }

  return errors;
};

const SearchClubValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchClubValidation;